import React from 'react';
import IndexNavbar from "../../components/Navbars/Navbar.js";
import Footer from "../../components/Footers/Footer.js";

function TermsOfUse() {
    return (
        <>
            <IndexNavbar fixed />
            <section
                id="early-access" className="header relative pt-20 pb-16 items-center flex " >
                <div className="container mx-auto items-center justify-center flex flex-wrap" id="register" >
                    <div className='container mx-auto'>
                        <div className="relative px-4 sm:px-6 lg:px-8">
                            <div className="text-lg max-w-prose mx-auto mb-6">
                                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center ">Terms and Conditions of Use</h2>
                            </div>
                        </div>
                        <div>
                            <div className="prose text-gray-500 mx-auto">
                                <p>
                                    These Terms and Conditions of Use (the "Terms") govern your access to and use of the PowerPointGeneratorAPI service (the "Service"), including any related software, websites, applications, and APIs (collectively, the "Platform") provided by PowerPointGeneratorAPI (the "Company," "we," "us," or "our"). By accessing or using the Platform, you ("you" or "your") agree to be bound by these Terms. If you do not agree to these Terms, do not access or use the Platform.
                                </p>
                            </div>
                            <div>
                                <p className="py-3 font-semibold">1. Acceptance of Terms</p>
                                <p>
                                    These Terms constitute a legally binding agreement between you and the Company. By accessing or using the Platform, you represent and warrant that you have the legal capacity to enter into this agreement and agree to be bound by these Terms. If you are using the Platform on behalf of an organization, you represent and warrant that you have the authority to bind that organization to these Terms.
                                </p>

                                <p className="py-2 font-semibold">2. Description of Service</p>
                                <p>
                                    The Service provides an API platform for populating PowerPoint files. The specific features and functionalities of the Service may vary depending on the subscription plan you choose.
                                </p>

                                <p className="py-3 font-semibold">3. User Accounts</p>
                                <div style={{ marginLeft: "30px" }}>
                                    <ul style={{ listStyle: "disc" }}>
                                        <li><span className="font-semibold">Registration:</span> To access certain features of the Platform, you may be required to create an account. You agree to provide accurate and complete information when registering for an account and to keep that information up-to-date. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</li>
                                        <li><span className="font-semibold">Account Security:</span> You must notify us immediately of any unauthorized access to or use of your account. We will not be liable for any loss or damage arising from your failure to protect your account credentials.</li>
                                        <li><span className="font-semibold">Contact Information:</span> For support or inquiries, please contact us at <a className="!underline font-semibold" href='mailto:support@powerpointgeneratorapi.com'><span>support@powerpointgeneratorapi.com</span></a>.</li>
                                    </ul>
                                </div>

                                <p className="py-3 font-semibold">4. User Responsibilities</p>
                                <p className='mb-3'>You agree not to:</p>
                                <div style={{ marginLeft: "30px" }}>
                                    <ul style={{ listStyle: "disc" }}>
                                        <li>Use the Platform for any illegal or unauthorized purpose.</li>
                                        <li>Violate any applicable laws or regulations.</li>
                                        <li>Infringe on any intellectual property rights.</li>
                                        <li>Interfere with or disrupt the Platform or its servers.</li>
                                        <li>Attempt to gain unauthorized access to any part of the Platform.</li>
                                        <li>Impersonate any person or entity.</li>
                                        <li>Use the Platform to transmit spam or other unsolicited communications.</li>
                                        <li>Reverse engineer, decompile, or disassemble any part of the Platform.</li>
                                        <li>Use the Platform to develop a competing product or service.</li>
                                        <li>Resell or sublicense access to the Platform without our prior written consent.</li>
                                    </ul>
                                </div>

                                <p className="py-3 font-semibold">5. Fees and Payment</p>
                                <div style={{ marginLeft: "30px" }}>
                                    <ul style={{ listStyle: "disc" }}>
                                        <li><span className="font-semibold">Subscription Plans:</span> Access to the Service may require payment of fees according to the subscription plan you select. The fees for each plan will be clearly displayed on the Platform.</li>
                                        <li><span className="font-semibold">Payment:</span> You agree to pay all fees due according to the payment method you choose.</li>
                                        <li><span className="font-semibold">Price Changes:</span> We reserve the right to change our fees at any time. We will provide you with reasonable notice of any price changes.</li>
                                        <li><span className="font-semibold">Refunds:</span> We offer prorated refunds for unused portions of your subscription term in the event of account termination by you, except in cases of termination due to your violation of these terms. No refunds are provided for usage within a billing cycle. Refund requests must be submitted in writing to <a className="!underline font-semibold" href='mailto:support@powerpointgeneratorapi.com'><span>support@powerpointgeneratorapi.com</span></a>.</li>
                                    </ul>
                                </div>

                                <p className="py-3 font-semibold">6. Ownership and Intellectual Property Rights</p>
                                <p>
                                    All ownership rights and Intellectual Property Rights in and to the Service and the Website shall vest in the Company or its licensors, and unless otherwise provided for expressly herein, the use of the Service permitted through the registration under the Terms shall not be construed as assigning, or granting any license with respect to, any Intellectual Property Rights relating to the Website or the Service held by the Company or its licensors. The Registered User shall not, for any reason whatsoever, perform any act which would infringe any Intellectual Property Rights of the Company or its licensors, including, without limitation, disassembling, decompiling, or reverse engineering.
                                    The ownership rights and Intellectual Property Rights of materials such as sentences and data entered into the Service by the Registered User and PowerPoint materials that are automatically created through the Service shall vest in the Registered User, except for templates and other versatile data reserved by the Company in the Service.
                                </p>

                                <p className="py-3 font-semibold">7. Disclaimer of Warranties</p>
                                <p className='uppercase'>THE PLATFORM IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. WE DO NOT WARRANT THAT THE PLATFORM WILL BE UNINTERRUPTED, ERROR-FREE, OR SECURE. WE DISCLAIM ALL WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.</p>

                                <p className="py-3 font-semibold">8. Limitation of Liability</p>
                                <p className='uppercase'>TO THE FULLEST EXTENT PERMITTED BY LAW, WE WILL NOT BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, 1 CONSEQUENTIAL, SPECIAL, OR EXEMPLARY DAMAGES (INCLUDING, BUT NOT LIMITED TO, LOST PROFITS, LOST DATA, OR BUSINESS INTERRUPTION) ARISING OUT OF OR IN CONNECTION WITH 2 YOUR USE OF THE PLATFORM, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. 3 OUR TOTAL LIABILITY TO YOU FOR ANY CLAIM ARISING OUT OF OR IN CONNECTION 4 WITH THESE TERMS, REGARDLESS OF THE FORM OF ACTION, WILL NOT EXCEED THE AMOUNT YOU HAVE PAID US IN THE TWELVE (12) MONTHS PRECEDING THE CLAIM. YOU EXPRESSLY AGREE THAT THIS LIMITATION OF LIABILITY IS A FUNDAMENTAL ELEMENT OF THESE TERMS AND THAT WE WOULD NOT PROVIDE THE PLATFORM TO YOU WITHOUT THIS LIMITATION</p>

                                <p className="py-3 font-semibold">9. Indemnification</p>
                                <p className=''>You agree to indemnify and hold us harmless from any claims, damages, or expenses (including attorney's fees) arising out of your use of the Platform or your breach of these Terms.</p>

                                <p className="py-3 font-semibold">10. Governing Law</p>
                                <p className=''>These Terms will be governed by and construed in accordance with the laws of England, UK, without regard to its conflict of law principles.</p>

                                <p className="py-3 font-semibold">11. Dispute Resolution</p>
                                <p className=''>Any disputes arising out of or in connection with these Terms will be resolved through mediation.</p>

                                <p className="py-3 font-semibold">12. Entire Agreement</p>
                                <p className=''>These Terms constitute the entire agreement between you and the Company regarding the Platform and supersede all prior agreements and understandings, whether written or oral.</p>

                                <p className="py-3 font-semibold">13. Changes to Terms</p>
                                <p className=''>We reserve the right to modify these Terms at any time. We will post the revised Terms on the Platform and notify you of any material changes. Your continued use of the Platform after the effective date of the revised Terms constitutes your acceptance of the revised Terms.</p>

                                <p className="py-3 font-semibold">14. Contact Us</p>
                                <p className=''>If you have any questions about these Terms, please contact us at:</p>
                                <p className='py-3'><a className="!underline font-semibold" href='mailto:support@powerpointgeneratorapi.com'><span>support@powerpointgeneratorapi.com</span></a>:</p>

                                <p className="py-3 font-semibold">15. Service Availability and Termination</p>
                                <div style={{ marginLeft: "30px" }}>
                                    <ul style={{ listStyle: "disc" }}>
                                        <li><span className="font-semibold">Availability:</span> We strive to maintain the availability of the Service, but we do not guarantee uninterrupted access. We may perform maintenance, updates, or repairs that may temporarily disrupt or suspend the Service.</li>
                                        <li><span className="font-semibold">Termination or Modification:</span> We reserve the right, in our sole discretion, to modify, suspend, discontinue, or terminate all or any part of the Service, temporarily or permanently, at any time, with or without notice, and without liability to you. This includes the right to close the Service entirely.</li>
                                        <li><span className="font-semibold">No Liability:</span> You acknowledge and agree that we will not be liable to you or any third party for any modification, suspension, discontinuance, or termination of the Service, or any loss of content or data that may result. This includes, but is not limited to, any loss of revenue, data, or business opportunities.</li>
                                        <li><span className="font-semibold">User Acceptance:</span> By using the Service, you acknowledge and accept the possibility of service interruptions, modifications, and termination as described in this section. You agree to hold us harmless for any consequences arising from such actions.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <Footer />
        </>
    );
}

export default TermsOfUse;
