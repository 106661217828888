/*eslint-disable*/
import React from "react";

import IndexNavbar from "components/Navbars/Navbar.js";
import Footer from "components/Footers/Footer.js";
import InfoAreaIconTitleList from "@notus-pro/react/InfoAreaIconTitleList";

import exampleJson from "../assets/json/example.json";

const props = {
  icon: "fas fa-toolbox",
  color: "lightBlue",
  title: "The power of PowerPoint Generator API",
  description: "",
  list: [
    { icon: "fas fa-check", text: "Automate PowerPoint document creation" },
    {
      icon: "fas fa-check",
      text: "Template driven, reusable and customisable",
    },
    {
      icon: "fas fa-check",
      text: "Design your templates in Powerpoint and stay on brand",
    },
    {
      icon: "fas fa-check",
      text: "Fill any PowerPoint objects from  charts to text boxes with your data",
    },
    {
      icon: "fas fa-check",
      text: "Automate updating dates, project and client names across all slides with tagging",
    },
    {
      icon: "fas fa-check",
      text: "Continue working on completely editable and native PowerPoint presentations",
    },
    {
      icon: "fas fa-check",
      text: "Available at the click of a button, instantly and at a low cost",
    },
  ],
};
const serviceProviderHeaders = [
  { label: "Service Provider" },
  { label: "Purpose" },
  { label: "Location" },
]
const serviceProviders = [
  {
    serviceProvider: "Amazon Web Services	",
    purpose: "AWS hosts all PGA sites and databases	",
    location: "Ireland",
  },
  {
    serviceProvider: "Xero",
    purpose: "This is a business accounting platform	",
    location: "USA",
  },
  {
    serviceProvider: "tawk.to	",
    purpose: "This tool enables website users and customers to message PGA	",
    location: "USA",
  },
  {
    serviceProvider: "Sendgrid",
    purpose: "Sending email	",
    location: "USA",
  },
  {
    serviceProvider: "Stripe",
    purpose: "Payment provider	",
    location: "USA",
  },
  {
    serviceProvider: "Zoho Subscription/Billing	",
    purpose: "Subscription plan manager	",
    location: "USA",
  },
]

export default function DataSecurity() {
  return (
    <>
      <IndexNavbar fixed />
      <section className="bg-white overflow-hidden">
        <div className="container mx-auto pb-32  pt-12">
          <div className="container mx-auto px-4 pb-6" id="cookies">
            <div className="justify-center text-left flex flex-wrap mt-24">
              <div className="w-full md:w-12/12 px-12 md:px-4">
                <h2 className="font-semibold text-4xl text-blueGray-600">
                  Data security
                </h2>

                <div class="mb-8 text-base text-blueGray-500">
                  <p class="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
                    <b>Data Encryption</b>
                  </p>
                  <div style={{ marginLeft: "30px" }}>
                    <ul style={{ listStyle: "disc" }}>
                      <li>All data in transit is encrypted using TLS 1.2.</li>
                      <li>All data is encrypted at rest. We use industry standard AES-256 encryption.</li>
                      <li>All of our website enforce the use of HTTPS.</li>
                      <li>All database data and backups are encrypted.</li>
                    </ul>
                  </div>

                  <p class="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
                    <b>Data Storage</b>
                  </p>
                  <div style={{ marginLeft: "30px" }}>
                    <ul style={{ listStyle: "disc" }}>
                      <li>Data is stored in our database services which are managed by AWS and located in Dublin, Ireland. Data storage on local machines is not permitted.</li>
                    </ul>
                  </div>

                  <p class="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
                    <b>Confidentiality</b>
                  </p>
                  <div style={{ marginLeft: "30px" }}>
                    <ul style={{ listStyle: "disc" }}>
                      <li>All employees and contractors are required to sign standardised employment or contractor agreements prior to their start date, which contain detailed confidentiality provisions.</li>
                    </ul>
                  </div>

                  <p class="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
                    <b>Security Certifications</b>
                  </p>
                  <div style={{ marginLeft: "30px" }}>
                    <ul style={{ listStyle: "disc" }}>
                      <li>PowerPointGeneratorAPI does not yet itself hold any security certifications (ISO, SOC, etc.). Our platform is built on infrastructure provided by AWS.</li>
                    </ul>
                  </div>

                  <p class="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
                    <b>PCI Compliance</b>
                  </p>
                  <div style={{ marginLeft: "30px" }}>
                    <ul style={{ listStyle: "disc" }}>
                      <li>Some of our customers may make payments by credit card via our website. We use a third party payment provider to process these payments, Zoho Subscriptions. Zoho Subscriptions securely stores and processes card data on our behalf, which significantly reduces our PCI compliance requirements as it means we don’t store or have access to any card information.</li>
                    </ul>
                  </div>

                  <p class="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
                    <b>Service Providers</b>
                  </p>
                  <table className="items-center w-full bg-transparent border-collapse">
                    <thead>
                      <tr>
                        {serviceProviderHeaders?.map((item, index) =>
                          <th key={index} className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                            {item.label}
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {serviceProviders?.map((item, index) =>
                        <tr key={index}>
                          <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                            {item.serviceProvider}
                          </th>
                          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                            {item.purpose}
                          </td>
                          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                            {item.location}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
