import ClipLoader from "react-spinners/ClipLoader";

const override = {
    opacity: 0.7
};

const Spinner = (props) => {

    return (
        <ClipLoader
            color={"#000000"}
            loading={true}
            cssOverride={override}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
        />
    );
};

export default Spinner;
