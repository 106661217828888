import React from "react";

const Footer = (props) => {
  return (
    <>
      <footer className="relative bg-blueGray-200 pt-8 pb-6">
        <div
          className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap text-center lg:text-left">
            <div className="w-full lg:w-6/12 px-4">
              <h4 className="text-3xl font-semibold">Get in touch!</h4>
              <h5 className="text-lg mt-0 mb-2 text-blueGray-600">
                support@powerpointgeneratorapi.com
              </h5>
              <div className="mt-6 lg:mb-0 mb-6">
                <button
                  className="bg-white text-lightBlue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                  onClick={() =>
                    window.open(
                      "https://www.linkedin.com/company/powerpoint-generator-api/",
                      "_blank"
                    )
                  }
                >
                  <i className="fab fa-linkedin"></i>
                </button>
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4">
              <div className="flex flex-wrap items-top mb-6">
                {props?.hideLinks ? (
                  <></>
                ) : (
                  <>
                    <div className="w-full lg:w-4/12 px-4 ml-auto">
                      <span className="block uppercase text-blueGray-500 text-sm font-semibold mb-2">
                        Useful Links
                      </span>
                      <ul className="list-unstyled">
                        <li>
                          <a
                            className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                            href="#register"
                          >
                            Get started free
                          </a>
                        </li>
                        <li>
                          <a
                            className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                            href="#what-included"
                          >
                            What's included
                          </a>
                        </li>
                        <li>
                          <a
                            className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                            href="#how-works"
                          >
                            How it works
                          </a>
                        </li>
                        <li>
                          <a
                            className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                            href="https://docs.powerpointgeneratorapi.com/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Documentation
                          </a>
                        </li>
                        <li>
                          <a
                            className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                            href="#about"
                          >
                            About Us
                          </a>
                        </li>
                        <li>
                          <a
                            className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                            href="#compare"
                          >
                            Compare
                          </a>
                        </li>
                        <li>
                          <a
                            className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                            href="https://github.com/Indico-Labs/PowerPoint-Generator-API-Community/discussions"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Forum
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div className="w-full lg:w-4/12 px-4 ml-auto">
                      <span className="block uppercase text-blueGray-500 text-sm font-semibold mb-2">
                        More
                      </span>
                      <ul className="list-unstyled">
                        <li>
                          <a
                            className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                            href="/datasecurity"
                          >
                            Data security
                          </a>
                        </li>
                        <li>
                          <a
                            className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                            href="/cookiepolicy"
                          >
                            Cookie policy
                          </a>
                        </li>
                      </ul>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <hr className="my-6 border-blueGray-300" />
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center">
              <div className="text-sm text-blueGray-500 font-semibold py-1">
                Copyright © {new Date().getFullYear()} PowerPoint Generator API
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
