/*eslint-disable*/
import React from "react";
import Footer from "components/Footers/Footer.js";
import { Link } from "react-router-dom";

export default function Welcome() {
  return (
    <>
      <section className="relative w-full h-full py-20">
        <div class="relative h-full text-center container mx-auto px-4 z-3 mb-16">
          <div class="justify-center flex flex-wrap -mx-4">
            <div class="px-12 px-4 relative w-full lg:w-6/12 w-full md:w-8/12">
              <h1 class="text-4xl font-bold leading-tight">Almost done...</h1>
              <p class="text-lg opacity-75 pt-2">
              We have sent an email to <span class="font-bold">{localStorage.getItem('verifyEmail')}</span> with a verification link.</p>
              <p class="text-lg opacity-75 pt-2">Open the email and click on the link to activate your account.</p>
              <p class="text-lg font-bold pt-2">Don't forget to check your spam inbox.</p>
              <Link to="/login">
                <button
                  style={{
                    background: "green",
                    padding: "10px",
                    color: "white",
                    marginTop: "10px",
                    borderRadius: "5px",
                    paddingLeft: "50px",
                    paddingRight: "50px",
                  }}
                  class="text-lg"
                >
                  Go to Sign In
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="relative w-full h-full py-1 min-h-screen"></section>
      <Footer />
    </>
  );
}
